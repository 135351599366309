<template>
  <div>
    <b-row>
      <b-col lg="6" order="1">
        <div class="mt-3 mt-lg-5 mb-3">
          <p class="f-size-28 f-medium text-center">รายละเอียด</p>
          <div class="d-flex align-items-center justify-content-between">
            <p class="f-size-28 f-medium mb-0">สภาพคล่อง</p>
            <img v-if="formResponse.financialLiquidity.status" src="@/assets/images/Correct.png" class="icon-result-title" />
            <img v-else src="@/assets/images/Wrong.png" class="icon-result-title" />
          </div>
          <div class="div-card mb-4">
            <div v-if="formResponse.financialLiquidity.status"> 
              <!-- // -->
              <p class="f-size-22 main-color f-medium">
                ยินดีด้วย…ท่านมีสภาพคล่องทางการเงินเป็นอย่างมาก
              </p>
              <div class="text-indent">
                หากเกิดเหตุการณ์ไม่คาดคิดที่ส่งผลต่อการทำงาน เช่น Lockdown เนื่องจากโรคระบาด 
                <span class="text-green">ท่านมีเงินเพียงพอที่จะดำรงชีวิตอยู่ได้ {{ formResponse.monthOfLife }} เดือน และมีความสามารถในการชำระหนี้ที่ท่านมีอยู่ได้อย่างแน่นอน</span>
              </div>
            </div>
            <div v-else-if="!formResponse.financialLiquidity.criterion1 && !formResponse.financialLiquidity.criterion2">
              <!-- xx -->
              <p class="f-size-22 text-fail f-medium">
                ท่านประสบปัญหาสภาพคล่องทางการเงินเป็นอย่างมาก
              </p>
              <div class="text-indent">
                หากเกิดเหตุการณ์ไม่คาดคิดที่ส่งผลต่อการทำงาน เช่น Lockdown เนื่องจากโรคระบาด 
                <span class="text-fail">ท่านมีเงินเพียงพอที่จะดำรงชีวิตอยู่ได้เพียง {{formResponse.monthOfLife}} เดือน </span>
                (ท่านควรมีเงินสำรองอย่างน้อย 6 เดือน) และ<span class="text-fail">เงินออมในปัจจุบันของท่านยังไม่เพียงพอที่จะชำระหนี้สินระยะสั้น</span> (หนี้ที่ต้องชำระคืนภายใน 1 ปี) ของท่าน
              </div>
            </div>
            <div v-else-if="!formResponse.financialLiquidity.criterion1">
              <!-- x/ -->
              <p class="f-size-22 text-fail f-medium">
                ท่านยังมีโอกาสประสบปัญหาสภาพคล่องทางการเงิน
              </p>
              <div class="text-indent">
                หากเกิดเหตุการณ์ไม่คาดคิดที่ส่งผลต่อการทำงาน เช่น Lockdown เนื่องจากโรคระบาด
                <span class="text-green">เงินออมในปัจจุบันของท่านเพียงพอที่จะชำระหนี้สินระยะสั้น</span>
                (หนี้ที่ต้องชำระคืนภายใน 1 ปี) ของท่าน 
                <span class="text-fail">แต่ท่านมีเงินเพียงพอที่จะดำรงชีวิตอยู่ได้เพียง {{formResponse.monthOfLife}} เดือน </span>
                (ท่านควรมีเงินสำรองอย่างน้อย 6 เดือน)
              </div>
            </div>
            <div v-else-if="!formResponse.financialLiquidity.criterion2">
              <!-- /x -->
              <p class="f-size-22 text-fail f-medium">
                ท่านยังมีโอกาสประสบปัญหาสภาพคล่องทางการเงิน
              </p>
              <div class="text-indent">
                หากเกิดเหตุการณ์ไม่คาดคิดที่ส่งผลต่อการทำงาน เช่น Lockdown เนื่องจากโรคระบาด
                <span class="text-green">ท่านมีเงินเพียงพอที่จะดำรงชีวิตอยู่ได้ {{formResponse.monthOfLife}} เดือน </span> 
                <span class="text-fail">แต่เงินออมในปัจจุบันของท่านยังไม่เพียงพอที่จะชำระหนี้สินระยะสั้น</span> 
                (หนี้ที่ต้องชำระคืนภายใน 1 ปี) ของท่าน
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <p class="f-size-28 f-medium mb-0">ความเสี่ยง</p>
            <img v-if="formResponse.financialRisk.status" src="@/assets/images/Correct.png" class="icon-result-title" />
            <img v-else src="@/assets/images/Wrong.png" class="icon-result-title" />
          </div>
          <div class="div-card mb-4">
            <div v-if="formResponse.financialRisk.status">
              <!-- // -->
              <p class="f-size-22 main-color f-medium">
                ยินดีด้วย…ท่านไม่มีความเสี่ยงทางการเงิน
              </p>
              <div class="text-indent">
                หากเกิดเหตุการณ์ไม่คาดคิด เช่น เจ็บป่วยร้ายแรง จนไม่สามารถทำงานต่อไปได้
                <span class="text-green">ท่านยังคงมีสินทรัพย์/ รายได้ที่เกิดจากการลงทุนเพียงพอต่อค่าใช้จ่ายรายเดือนของท่าน</span>
                หรือกรณีท่านเสียชีวิต
                <span class="text-green">เงินของท่านยังเพียงพอครอบคลุมค่าใช้จ่ายของครอบครัวหรือหนี้สินต่าง ๆ ของท่านอีก {{formResponse.yearOfLife}} ปี</span>
              </div>
            </div>
            <div v-else-if="!formResponse.financialRisk.criterion1 && !formResponse.financialRisk.criterion2">
              <!-- xx -->
              <p class="f-size-22 text-fail f-medium">
                ท่านมีความเสี่ยงทางการเงินเป็นอย่างมาก
              </p>
              <div class="text-indent">
                หากเกิดเหตุการณ์ไม่คาดคิด เช่น เจ็บป่วยร้ายแรง จนไม่สามารถทำงานต่อไปได้ สินทรัพย์/ รายได้ที่เกิดจากการลงทุนของท่านไม่เพียงพอต่อค่าใช้จ่ายรายเดือนของท่าน และกรณีท่านเสียชีวิต ท่านอาจไม่มีเงินสำรองสำหรับครอบครัว หรือเงินของท่านอาจไม่เพียงพอต่อค่าใช้จ่ายของครอบครัวหรือหนี้สินต่าง ๆ ของท่าน
              </div>
            </div>
            <div v-else-if="!formResponse.financialRisk.criterion1">
              <!-- x/ -->
              <p class="f-size-22 text-fail f-medium">
                ท่านยังมีโอกาสประสบปัญหาความเสี่ยงทางการเงิน
              </p>
              <div class="text-indent">
                กรณีท่านเสียชีวิต
                <span class="text-green">เงินของท่านเพียงพอครอบคลุมค่าใช้จ่ายของครอบครัวหรือหนี้สินต่าง ๆ ของท่านอีก {{formResponse.yearOfLife}} ปี</span>
                (ท่านควรมีเงินสำรองสำหรับครอบครัวอย่างน้อย 5 ปี) แต่หากเกิดเหตุการณ์ไม่คาดคิด เช่น เจ็บป่วยร้ายแรง จนไม่สามารถทำงานต่อไปได้
                <span class="text-fail">สินทรัพย์/ รายได้ที่เกิดจากการลงทุนของท่านไม่เพียงพอต่อค่าใช้จ่ายรายเดือนของท่าน</span>
              </div>
            </div>
            <div v-else-if="!formResponse.financialRisk.criterion2">
              <!-- /x -->
              <p class="f-size-22 text-fail f-medium">
                ท่านยังมีโอกาสประสบปัญหาความเสี่ยงทางการเงิน
              </p>
              <div class="text-indent">
                หากเกิดเหตุการณ์ไม่คาดคิด เช่น เจ็บป่วยร้ายแรง จนไม่สามารถทำงานต่อไปได้ ท่านยังคงมีสินทรัพย์/ รายได้ที่เกิดจากการลงทุนเพียงพอต่อค่าใช้จ่ายรายเดือนของท่าน แต่กรณีท่านเสียชีวิต
                <span class="text-fail">ท่านอาจไม่มีเงินสำรองสำหรับครอบครัว หรือเงินของท่านอาจไม่เพียงพอต่อค่าใช้จ่ายของครอบครัวหรือหนี้สินต่าง ๆ ของท่าน</span>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <p class="f-size-28 f-medium mb-0">อิสระภาพทางการเงิน</p>
            <img v-if="formResponse.financialIndependence" src="@/assets/images/Correct.png" class="icon-result-title" />
            <img v-else src="@/assets/images/Wrong.png" class="icon-result-title" />
          </div>
          <div class="div-card mb-4">
            <div v-if="formResponse.financialIndependence">
              <p class="f-size-22 main-color f-medium">
                ยินดีด้วย…ท่านมีอิสรภาพทางการเงิน
              </p>
              <div class="text-indent">
                เนื่องจาก<span class="text-green">ท่านควรมีเงินออมเพื่อให้เพียงพอต่อภาระค่าใช้จ่ายหลังเกษียณอายุรวมทั้งสิ้น {{ formResponse.retirementMoney.toLocaleString() }} บาท</span> 
                ซึ่งจำนวนเงินออมในปัจจุบันของท่านมากกว่าเงินออมที่ควรมีเรียบร้อยแล้ว
              </div>
            </div>
            <div v-else>
              <p class="f-size-22 text-fail f-medium">
                ท่านยังขาดอิสรภาพทางการเงิน
              </p>
              <div class="text-indent">
                เนื่องจาก<span class="text-fail">ท่านควรมีเงินออมเพื่อให้เพียงพอต่อภาระค่าใช้จ่ายหลังเกษียณอายุรวมทั้งสิ้น {{ formResponse.retirementMoney.toLocaleString() }} บาท</span>
                แต่จำนวนเงินออมในปัจจุบันของท่านยังไม่ครอบคลุมเงินออมที่ท่านควรมีเพื่อให้เพียงพอต่อค่าใช้จ่ายภายหลังการเกษียณอายุ
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="6" order="3">
        <div class="my-3 mb-md-5 text-center">
          <b-button
            variant="link-main"
            type="button"
            class="mb-3"
            @click="$emit('goToStep', 2)"
          >
            ลองทำแบบทดสอบใหม่อีกครั้ง
          </b-button>
          <b-button
            variant="main"
            type="button"
            block
            :to="`/retirement-planning?sessionId=${$route.query.sessionId}`"
            >
            วางแผนเงินออมเพื่อเกษียณ
          </b-button>
        </div>
      </b-col>
      <b-col
        lg="6"
        class="d-flex align-items-center justify-content-center"
        order="2"
      >
        <img
          v-if="count === 3"
          src="@/assets/images/Success-3.png"
          class="w-75 img-result"
        />
        <img
          v-else-if="count === 2"
          src="@/assets/images/Success-2.png"
          class="w-75 img-result"
        />
        <img
          v-else-if="count === 1"
          src="@/assets/images/Success-1.png"
          class="w-75 img-result"
        />
        <img
          v-else-if="count === 0"
          src="@/assets/images/Penguin-fail.png"
          class="w-75 img-result"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  props: {
    formResponse: {
      required: true,
      type: Object
    },
    step: {
      required: true,
      type: [Number, String]
    },
    count: {
      required: true,
      type: [Number, String]
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-form {
  p {
    margin-bottom: 0;
  }
}
.div-card {
  background-color: #fff;
  width: 100%;
  padding: 15px;
  border-radius: 20px;
  border: 3px solid #ff6439;
  box-shadow: 0px 3px 30px #00000021;
}
.img-result {
  margin-top: 150px;
}
.icon-result-title {
  height: 25px;
}
@media (max-width: 991.98px) { 
  .img-result {
    margin-top: 0px;
    width: 60% !important;
  }
}
@media (max-width: 767.98px) {
  .panel-form {
    padding: 15px;
    box-shadow: unset !important;
    border: 0;
  }
  .img-result {
    margin-top: 0px;
    width: 100% !important;
  }
}
</style>
