<template>
  <div>
    <b-row>
      <b-col lg="6" order="1">
        <div class="mt-3 my-lg-5" v-if="step === 3">
          <p class="f-size-28 f-medium text-center">ผลลัพธ์</p>
          <b-row>
            <b-col cols="12" order-md="1" order="1" v-if="textResult.status">
              <div class="div-card mb-5">
                <div>
                  <p class="f-size-22 main-color f-medium"> {{ textResult.title }} </p>
                  <div class="text-indent"> {{ textResult.detail }} </div>
                </div>
              </div>
            </b-col>
            <b-col cols="12" order-md="2" order="2" class="result-titles">
              <div class="">
                <b-row align-v="center">
                  <b-col>
                    <p>สภาพคล่อง</p>
                  </b-col>
                  <b-col>
                    <p>ความเสี่ยง</p>
                  </b-col>
                  <b-col>
                    <p>อิสระภาพทางการเงิน</p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div v-if="formResponse.financialLiquidity.status">
                      <img src="@/assets/images/Correct.png" />
                      <p class="text-success">ผ่าน</p>
                    </div>
                    <div v-else>
                      <img src="@/assets/images/Wrong.png" />
                      <p class="text-fail">ไม่ผ่าน</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div v-if="formResponse.financialRisk.status">
                      <img src="@/assets/images/Correct.png" />
                      <p class="text-success">ผ่าน</p>
                    </div>
                    <div v-else>
                      <img src="@/assets/images/Wrong.png" />
                      <p class="text-fail">ไม่ผ่าน</p>
                    </div>
                  </b-col>
                  <b-col>
                    <div v-if="formResponse.financialIndependence">
                      <img src="@/assets/images/Correct.png" />
                      <p class="text-success">ผ่าน</p>
                    </div>
                    <div v-else>
                      <img src="@/assets/images/Wrong.png" />
                      <p class="text-fail">ไม่ผ่าน</p>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col cols="12" order-md="3" order="1" v-if="!textResult.status">
              <div class="div-card my-3">
                <p class="f-size-22 main-color f-medium"> {{ textResult.title }} </p>
                <div class="text-indent"> {{ textResult.detail }} </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col lg="6" order="3">
        <div class="my-3 mb-md-5 text-center">
          <b-button
            variant="link-main"
            type="button"
            class="mb-3"
            @click="$emit('handleReset')"
          >
            ลองทำใหม่อีกครั้ง
          </b-button>
          <b-button
            variant="main"
            type="button"
            block
            class="mb-3"
            @click="$emit('goToStep', 4)"
          >
            ดูรายละเอียด
          </b-button>
          <b-button
            variant="main"
            type="button"
            block
            @click="$emit('confirmSubmit')"
          >
            ยืนยันผลการทดสอบ
          </b-button>
        </div>
      </b-col>
      <b-col
        lg="6"
        class="d-flex align-items-center justify-content-center"
        order="2"
      >
        <img
          v-if="count === 3"
          src="@/assets/images/Success-3.png"
          class="w-75 img-result"
        />
        <img
          v-else-if="count === 2"
          src="@/assets/images/Success-2.png"
          class="w-75 img-result"
        />
        <img
          v-else-if="count === 1"
          src="@/assets/images/Success-1.png"
          class="w-75 img-result"
        />
        <img
          v-else-if="count === 0"
          src="@/assets/images/Penguin-fail.png"
          class="w-75 img-result"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    formResponse: {
      required: true,
      type: Object
    },
    step: {
      required: true,
      type: [Number, String]
    },
    count: {
      required: true,
      type: [Number, String]
    }
  },
  computed: {
    textResult() {
      let result = {
        title: '',
        detail: '',
        status: true
      };
      if(this.formResponse.financialLiquidity.status){
        if(this.formResponse.financialRisk.status){
          if(this.formResponse.financialIndependence){
            //  [///]
            result.title = 'ยินดีด้วย…ภาวะทางการเงินของท่านมีความเข้มแข็งเป็นอย่างมาก'
            result.detail = 'ไม่มีความเสี่ยงในการเกิดภาวะวิกฤตทางการเงิน และมีอิสรภาพทางการเงินอย่างแท้จริง'
          } else{
            //  [//x]
            result.title = 'ท่านมีสภาพคล่องทางการเงิน และไม่มีความเสี่ยงทางการเงิน'
            result.detail = 'อย่างไรก็ตาม ท่านควรมีการวางแผนลงทุน/ ออมเงินเพิ่มเติมเพื่อรองรับการดำรงชีวิตภายหลังเกษียณอายุ'
          }
        }else{
          if(this.formResponse.financialIndependence){
            //  [/x/]
            result.title = 'ท่านมีสภาพคล่องทางการเงิน และมีอิสระภาพทางการเงิน มีเงินเพียงพอรองรับการเกษียณอายุ'
            result.detail = 'อย่างไรก็ตาม ท่านควรมีการวางแผนบริหารความเสี่ยงทางการเงินให้มากขึ้น เช่น การลงทุนในกองทุน ทำประกันอุบัติเหตุ'
          } else{
            //  [/xx]
            result.title = 'ท่านมีสภาพคล่องทางการเงิน มีเงินเพียงพอกรณีฉุกเฉินในระยะสั้น แต่ยังมีความเสี่ยงทางการเงินในระยะยาว'
            result.detail = 'ดังนั้น ควรมีการวางแผนบริหารความเสี่ยงทางการเงินให้มากขึ้น เช่น การลงทุนในกองทุน ทำประกันอุบัติเหตุ รวมไปถึงการวางแผนลงทุน/ ออมเงินเพิ่มเติมเพื่อรองรับการดำรงชีวิตภายหลังเกษียณอายุ'
          }
        }
      }else {
        if(this.formResponse.financialRisk.status){
          if(this.formResponse.financialIndependence){
            //  [x//]
            result.title = 'ท่านไม่มีความเสี่ยงทางการเงิน และยังมีอิสระภาพทางการเงิน มีเงินเพียงพอรองรับการเกษียณอายุ'
            result.detail = 'อย่างไรก็ตาม ท่านยังมีปัญหาสภาพคล่องทางการเงินในระยะสั้น ดังนั้น ท่านควรวางแผนออมเงินให้มากขึ้น หรือลดค่าใช้จ่าย หรือเร่งรัดการชำระหนี้สินระยะสั้น'
          } else{
            //  [x/x]
            result.title = 'ท่านไม่มีความเสี่ยงทางการเงิน แต่ยังมีปัญหาสภาพคล่องทางการเงินในระยะสั้น และอิสระภาพทางการเงินในระยะยาว'
            result.detail = 'ดังนั้น ท่านควรวางแผนออมเงินให้มากขึ้น หรือลดค่าใช้จ่าย หรือเร่งรัดการชำระหนี้สิน รวมไปถึงการวางแผนลงทุน/ ออมเงินเพิ่มเติมเพื่อรองรับการดำรงชีวิตภายหลังเกษียณอายุ'
          }
        }else{
          if(this.formResponse.financialIndependence){
            //  [xx/]
            result.title = 'ท่านมีอิสระภาพทางการเงิน มีเงินเพียงพอรองรับการเกษียณอายุ แต่ยังมีปัญหาสภาพคล่องทางการเงิน และความเสี่ยงทางการเงิน'
            result.detail = 'ดังนั้น ท่านควรวางแผนออมเงินให้มากขึ้น หรือลดค่าใช้จ่าย หรือเร่งรัดการชำระหนี้สินระยะสั้น รวมไปถึงการวางแผนบริหารความเสี่ยงทางการเงินให้มากขึ้น เช่น การลงทุนในกองทุน ทำประกันอุบัติเหตุ'
          } else{
            //  [xxx]
            result.title = 'ท่านยังประสบปัญหาทางการเงินเป็นอย่างมากทั้งด้านสภาพคล่อง ความเสี่ยง และอิสรภาพทางการเงิน'
            result.detail = 'ดังนั้น ท่านควรมีการวางแผนออมเงินหรือลงทุนให้มากขึ้น และลดค่าใช้จ่าย/ หนี้สิน'
            result.status = false
          }
        }
      }
      this.$emit("handleTextDetail", result);

      return result
    }
  },
};
</script>

<style lang="scss" scoped>
.panel-form {
  p {
    margin-bottom: 0;
  }
}
.div-card {
  background-color: #fff;
  width: 100%;
  padding: 15px;
  border-radius: 20px;
  border: 3px solid #ff6439;
  box-shadow: 0px 3px 30px #00000021;
}
.img-result {
  margin-top: 150px;
}
.result-titles {
  color: #FF761A;
  font-family: Kanit-Medium;
  text-align: center !important;
  font-size: 28px;
  img {
    height: 100px;
  }
}
@media (max-width: 991.98px) { 
  .img-result {
    margin-top: 0px;
    width: 60% !important;
  }
}
@media (max-width: 767.98px) {
  .panel-form {
    padding: 15px;
    box-shadow: unset !important;
    border: 0;
  }
  .result-titles {
    font-size: 18px;
    img {
      height: 50px;
    }
  }
  .img-result {
    margin-top: 0px;
    width: 100% !important;
  }
}
</style>
