<template>
  <div>
    <b-row v-if="step === 1">
      <b-col lg="6" order="1" order-md="0" class="mt-3 my-lg-5">
        <div class="mb-3">
          <p class="f-size-28 f-medium mb-0 text-center">
            การทดสอบภาวะวิกฤตทางการเงิน
          </p>
          <p class="f-size-28 f-medium text-center">
            (Stress Testing) คืออะไร ?
          </p>
          <div class="div-card">
            <p class="f-size-22 main-color f-medium">
              การทดสอบภาวะวิกฤตทางการเงิน
            </p>
            <div class="text-indent">
              เป็นการทดสอบความเข้มแข็งทางการเงินของท่าน
              ว่ามีความพร้อมที่จะรับมือกับเหตุการณ์ หรือวิกฤตต่าง ๆ
              ที่อาจจะเกิดขึ้นในชีวิตได้หรือไม่
            </div>
          </div>
        </div>
        <div>
          <p class="f-size-28 f-medium text-center">ทดสอบอะไรบ้าง ?</p>
          <div class="div-card mb-3">
            <p class="f-size-22 main-color f-medium">สภาพคล่อง</p>
            <div class="text-indent">
              หากเกิดเหตุการณ์ไม่คาดคิดที่ส่งผลต่อการทำงาน เช่น Lockdown
              เนื่องจากโรคระบาด ท่านจะมีเงินเพียงพอที่จะดำรงชีวิตอยู่ได้กี่เดือน
              และมีความสามารถในการชำระหนี้หรือไม่
            </div>
          </div>
          <div class="div-card mb-3">
            <p class="f-size-22 main-color f-medium">ความเสี่ยง</p>
            <div class="text-indent">
              หากเกิดเหตุการณ์ไม่คาดคิด เช่น เจ็บป่วยร้ายแรง
              จนไม่สามารถทำงานต่อไปได้
              ท่านจะสามารถดำรงชีวิตหลังจากนี้โดยปราศจากปัญหาทางการเงินหรือไม่
              และมีเงินเพียงพอครอบคลุมค่าใช้จ่ายของครอบครัวหรือหนี้สินต่าง ๆ
              หรือไม่
            </div>
          </div>
          <div class="div-card">
            <p class="f-size-22 main-color f-medium">อิสรภาพทางการเงิน</p>
            <div class="text-indent">
              หากถึงวัยเกษียณอายุ
              ท่านมีเงินเพียงพอที่จะใช้ในการดำรงชีวิตหลังการเกษียณอายุหรือไม่
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="6" order="3">
        <b-button variant="main" type="button" class="mb-3 mb-md-5" block @click="goToStep(2)">
          เริ่มการทดสอบ
        </b-button>
      </b-col>
      <b-col
        order="2"
        order-md="1"
        lg="6"
        class="d-flex align-items-center justify-content-center"
      >
        <img src="@/assets/images/Penguin.png" class="w-75 my-3" />
      </b-col>
    </b-row>
    <b-row v-else-if="step === 2">
      <b-col lg="6" order="1" order-md="0">
        <div class="panel-form my-3 my-md-5">
          <p class="title text-center">ทดสอบภาวะวิกฤตทางการเงิน</p>
          <InputRang
            textFloat="สินทรัพย์, รายได้จากการลงทุน เช่น สลากออมสิน กองทุน หุ้น (บาท / เดือน)"
            type="text"
            id="incomeFromInvestment"
            name="incomeFromInvestment"
            :min="form.incomeFromInvestment.min"
            :max="form.incomeFromInvestment.max"
            :step="form.incomeFromInvestment.scale"
            v-model="form.incomeFromInvestment.value"
            :isValidate="$v.form.incomeFromInvestment.value.$error"
            :v="$v.form.incomeFromInvestment.value"
            xtextGuide="ลองเกษียณอายุข้าลงไหม?"
          />
          <InputRang
            textFloat="จำนวนเงินออมในปัจจุบัน (บาท)"
            type="text"
            id="saving"
            name="saving"
            :min="form.saving.min"
            :max="form.saving.max"
            :step="form.saving.scale"
            v-model="form.saving.value"
            :isValidate="$v.form.saving.value.$error"
            :v="$v.form.saving.value"
          />
          <InputRang
            textFloat="ค่าใช้จ่ายทั้งหมด (บาท / เดือน)"
            type="text"
            id="expensePerMonth"
            name="expensePerMonth"
            :min="form.expensePerMonth.min"
            :max="form.expensePerMonth.max"
            :step="form.expensePerMonth.scale"
            v-model="form.expensePerMonth.value"
            :isValidate="$v.form.expensePerMonth.value.$error"
            :v="$v.form.expensePerMonth.value"
          />

          <InputRang
            textFloat="ค่าใช้จ่าย (เฉพาะส่วนของครอบครัว) (บาท / เดือน)"
            type="text"
            id="familyExpense"
            name="familyExpense"
            :min="form.familyExpense.min"
            :max="form.familyExpense.max"
            :step="form.familyExpense.scale"
            v-model="form.familyExpense.value"
            :isValidate="$v.form.familyExpense.value.$error"
            :v="$v.form.familyExpense.value"
          />
          <InputRang
            textFloat="จำนวนหนี้สินทั้งหมด (บาท)"
            type="text"
            id="dept"
            name="dept"
            :min="form.dept.min"
            :max="form.dept.max"
            :step="form.dept.scale"
            v-model="form.dept.value"
            :isValidate="$v.form.dept.value.$error"
            :v="$v.form.dept.value"
          />
          <InputRang
            textFloat="จำนวนหนี้สินระยะสั้น (หนี้ที่ต้องชำระคืนภายใน 1 ปี) (บาท)"
            type="text"
            id="shortTermDept"
            name="shortTermDept"
            :min="form.shortTermDept.min"
            :max="form.shortTermDept.max"
            :step="form.shortTermDept.scale"
            v-model="form.shortTermDept.value"
            :isValidate="$v.form.shortTermDept.value.$error"
            :v="$v.form.shortTermDept.value"
          />
          <InputRang
            textFloat="เงินที่จะได้จากการทำประกัน หากเสียชีวิต หรือไม่ สามารถทำงานต่อไปได้ (บาท)"
            type="text"
            id="lifeInsurance"
            name="lifeInsurance"
            :min="form.lifeInsurance.min"
            :max="form.lifeInsurance.max"
            :step="form.lifeInsurance.scale"
            v-model="form.lifeInsurance.value"
            :isValidate="$v.form.lifeInsurance.value.$error"
            :v="$v.form.lifeInsurance.value"
          />
          <InputRang
            textFloat="อายุที่คาดว่าจะเกษียณ (ปี)"
            type="text"
            id="expectRetireAge"
            name="expectRetireAge"
            :min="form.expectRetireAge.min"
            :max="form.expectRetireAge.max"
            :step="form.expectRetireAge.scale"
            v-model="form.expectRetireAge.value"
            :isValidate="$v.form.expectRetireAge.value.$error"
            :v="$v.form.expectRetireAge.value"
          />
          <InputRang
            textFloat="อยากใช้เงินหลังเกษียณจนถึงอายุเท่าไหร่ (ปี)"
            type="text"
            id="expectSpendAge"
            name="expectSpendAge"
            :min="form.expectSpendAge.min"
            :max="form.expectSpendAge.max"
            :step="form.expectSpendAge.scale"
            v-model="form.expectSpendAge.value"
            :isValidate="$v.form.expectSpendAge.value.$error"
            :v="$v.form.expectSpendAge.value"
          />

          <div class="mt-3">
            <b-button
              variant="main"
              type="button"
              block
              class="mt-5"
              @click="checkForm"
              :disabled="btnDisabled"
            >
              ประมวลผล
            </b-button>
            <b-button
              variant="outnline-main"
              type="button"
              block
              @click="goToStep(1)"
            >
              ย้อนกลับ
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col
        order="0"
        order-md="1"
        lg="6"
        class="d-flex align-items-center justify-content-center"
      >
        <img src="@/assets/images/Penguin2.png" class="w-75 mt-5 my-md-5" />
      </b-col>
    </b-row>

    <div v-else-if="step === 3">
      <Result
        :formResponse="formResponse"
        @goToStep="goToStep"
        @confirmSubmit="confirmSubmit"
        @handleReset="handleReset"
        @handleTextDetail="handleTextDetail"
        :step="step"
        :count="count"
      />
    </div>
    <div v-else-if="step === 4">
      <ResultDetails
        :formResponse="formResponse"
        @goToStep="goToStep"
        @handleReset="handleReset"
        :step="step"
        :count="count"
      />
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import InputRang from "@/components/input/InputRang";
import Result from "./components/Result.vue";
import ResultDetails from "./components/ResultDetails.vue";
import {
  required,
  minValue,
  maxValue,
  numeric,
  decimal
} from "vuelidate/lib/validators";
import ModalLoading from "@/components/alert-modal/ModalLoading";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalAlertError from "@/components/alert-modal/ModalAlertError";

export default {
  components: {
    InputRang,
    ModalLoading,
    ModalAlert,
    ModalAlertError,
    Result,
    ResultDetails
  },
  data() {
    return {
      step: 1,
      loading: true,
      btnDisabled: false,
      lineProfile: {
        userId: "",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      modalMessage: "",
      textDetail: "",
      formSubmit: {
        userSessionId: "",
        incomeFromInvestment: 0,
        saving: 0,
        expensePerMonth: 0,
        familyExpense: 0,
        debt: 0,
        shortTermDept: 0,
        lifeInsurance: 0,
        expectRetireAge: 0,
        expectSpendAge: 0
      },
      form: {
        incomeFromInvestment: { value: 0, min: 0, max: 5000000, scale: 1 },
        saving: { value: 0, min: 0, max: 5000000, scale: 1 },
        expensePerMonth: { value: 0, min: 1, max: 5000000, scale: 1 },
        familyExpense: { value: 0, min: 0, max: 5000000, scale: 1 },
        dept: { value: 0, min: 0, max: 5000000, scale: 1 },
        shortTermDept: { value: 0, min: 0, max: 5000000, scale: 1 },
        lifeInsurance: { value: 0, min: 0, max: 5000000, scale: 1 },
        expectRetireAge: { value: 0, min: 1, max: 120, scale: 1 },
        expectSpendAge: { value: 0, min: 1, max: 120, scale: 1 }
      },
      formResponse: {
        financialLiquidity: {
          status: false,
          criterion1: false,
          criterion2: false
        },
        financialRisk: {
          status: false,
          criterion1: false,
          criterion2: false
        },
        financialIndependence: false,
        monthOfLife: 0,
        yearOfLife: 0,
        retirementMoney: 0
      },
      isTestAgain: false,
      sessionId: ""
    };
  },
  validations() {
    return {
      form: {
        incomeFromInvestment: {
          value: {
            required,
            numeric,
            minValue: minValue(this.form.incomeFromInvestment.min),
          }
        },
        saving: {
          value: {
            required,
            numeric,
            minValue: minValue(this.form.saving.min),
          }
        },
        expensePerMonth: {
          value: {
            required,
            numeric,
            handleExpensePerMonthMin: (value) => {
              return this.handleExpensePerMonthMin(value);
            },
          }
        },
        familyExpense: {
          value: {
            required,
            decimal,
            minValue: minValue(this.form.familyExpense.min),
            handleFamilyExpenseValue: (value) => {
              return this.handleFamilyExpenseValue(value);
            },
          }
        },
        dept: {
          value: {
            required,
            decimal,
            minValue: minValue(this.form.dept.min),
            handleDeptValue: (value) => {
              return this.handleDeptValue(value);
            },
          }
        },
        shortTermDept: {
          value: {
            required,
            numeric,
            minValue: minValue(this.form.shortTermDept.min),
            handleShortTermDeptValue: (value) => {
              return this.handleShortTermDeptValue(value);
            },
          }
        },
        lifeInsurance: {
          value: {
            required,
            numeric,
            minValue: minValue(this.form.lifeInsurance.min),
          }
        },
        expectRetireAge: {
          value: {
            required,
            numeric,
            maxValue: maxValue(this.form.expectRetireAge.max),
            handleExpectRetireAgeMin: (value) => {
              return this.handleExpectRetireAgeMin(value);
            },
            handleExpectRetireAgeValue: (value) => {
              return this.handleExpectRetireAgeValue(value);
            },
          }
        },
        expectSpendAge: {
          value: {
            required,
            numeric,
            maxValue: maxValue(this.form.expectSpendAge.max),
            handleExpectSpendAgeMin: (value) => {
              return this.handleExpectSpendAgeMin(value);
            },
            handleExpectSpendAgeValue: (value) => {
              return this.handleExpectSpendAgeValue(value);
            },
          }
        }
      }
    };
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      this.$liff
        .init({ liffId: this.$liff_Main_ID })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  created: async function () {
    this.sessionId = this.$route.query.sessionId;
    if (this.sessionId) await this.getUserData();
    else this.$router.push("/session-expired");
  },
  computed: {
    count() {
      let count = 0;
      if(this.formResponse.financialLiquidity.status) count+=1
      if(this.formResponse.financialRisk.status) count+=1
      if(this.formResponse.financialIndependence) count+=1

      return count
    }
  },
  methods: {
    handleExpensePerMonthMin(value) {
      return (parseInt(value) >= parseInt(this.form.expensePerMonth.min));
    },
    handleExpectRetireAgeValue(value) {
      return !(parseInt(value) > parseInt(this.form.expectSpendAge.value));
    },
    handleExpectRetireAgeMin(value) {
      return (parseInt(value) >= parseInt(this.form.expectRetireAge.min));
    },
    handleExpectSpendAgeValue(value) {
      return !(parseInt(value) < parseInt(this.form.expectRetireAge.value));
    },
    handleExpectSpendAgeMin(value) {
      return (parseInt(value) >= parseInt(this.form.expectSpendAge.min));
    },
    handleDeptValue(value) {
      return !(parseInt(value) < parseInt(this.form.shortTermDept.value));
    },
    handleShortTermDeptValue(value) {
      return !(parseInt(value) > parseInt(this.form.dept.value));
    },
    handleFamilyExpenseValue(value) {
      return !(parseInt(value) > parseInt(this.form.expensePerMonth.value));
    },
    checkForm: async function () {
      this.$v.$touch();
      if (this.$v.$error) {
        return;
      }
      this.submit();
    },
    submit: async function () {
      this.formSubmit = {
        userSessionId: this.sessionId,
        incomeFromInvestment: this.form.incomeFromInvestment.value,
        saving: this.form.saving.value,
        expensePerMonth: this.form.expensePerMonth.value,
        familyExpense: this.form.familyExpense.value,
        debt: this.form.dept.value,
        shortTermDept: this.form.shortTermDept.value,
        lifeInsurance: this.form.lifeInsurance.value,
        expectRetireAge: this.form.expectRetireAge.value,
        expectSpendAge: this.form.expectSpendAge.value
      };
      this.btnDisabled = true;
      this.$refs.modalLoading.show();
      await this.$axios
        .post(`${this.$baseUrl}/user/Saving/StressTesting`, this.formSubmit)
        .then(async data => {
          if (data.data.result == 1) {
            await this.goToStep(3);
            this.formResponse = { ...data.data.detail };
            this.isTestAgain = true;
          } else {
            this.modalMessage = data.data.detail[0];
            this.$refs.modalAlertError.show();
          }
          this.btnDisabled = false;
          this.$refs.modalLoading.hide();
        });
    },
    getUserData: async function () {
      await this.$store.commit("UPDATE_IS_LOADING_GLOBAL", true);
      await this.$axios
        .get(this.$baseUrl + "/form/FinancialCrisis/" + this.sessionId)
        .then(response => {
          if (response.data.result === 1) {
            let data = response.data.detail;
            this.form.dept.value = data.dept.value;
            this.form.expectRetireAge.value = data.expectRetireAge.value;
            this.form.expectSpendAge.value = data.expectSpendAge.value;
            this.form.expensePerMonth.value = data.expensePerMonth.value;
            this.form.familyExpense.value = data.familyExpense.value;
            this.form.incomeFromInvestment.value = data.incomeFromInvestment.value;
            this.form.lifeInsurance.value = data.lifeInsurance.value;
            this.form.saving.value = data.saving.value;
            this.form.shortTermDept.value = data.shortTermDept.value;
          } else {
            this.$router.push("/session-expired");
          }
        });
      await this.$store.commit("UPDATE_IS_LOADING_GLOBAL", false);
    },
    goToStep: async function (step) {
      await this.scrollToTop();
      this.step = step;
    },
    handleReset: async function () {
      this.$v.$reset();
      this.isTestAgain = false;
      await this.goToStep(2);
      // this.getUserData();
    },
    handleTextDetail: async function (text) {
      this.textDetail = `${text.title} ${text.detail}`;
    },
    confirmSubmit: async function () {
      this.btnDisabled = true;
      this.$refs.modalLoading.show();

      let body = { 
        sessionId: this.sessionId,
        financialLiquidity: this.formResponse.financialLiquidity.status,
        financialRisk: this.formResponse.financialRisk.status,
        financialIndependence: this.formResponse.financialIndependence,
        detail: this.textDetail,
        ...this.formSubmit
      }
      await this.$axios.post(this.$baseUrl + "/User/Saving/StressTest/Message", body)
        .then(response => {
          if (!response.data.result) {
            this.modalMessage = response.data.detail;
            this.$refs.modalAlertError.show();
          }
        })
        .catch(error => {
          this.modalMessage = error;
          this.$refs.modalAlertError.show();
        });

      await this.closeWindow();
      this.btnDisabled = false;
      this.$refs.modalLoading.hide();
    },
    closeWindow() {
      console.log("closeWindow is worked windows");
      try {
        window.close();
      } catch (error) {
        console.log(error);
      }

      console.log("closeWindow is worked leff");
      try {
        this.$liff.closeWindow();
      } catch (error) {
        console.log(error);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss" scoped>
.text-error {
  color: #dc3545 !important;
  font-weight: bold;
  font-size: 16px;
}
.div-card {
  background-color: #fff;
  width: 100%;
  padding: 15px;
  border-radius: 20px;
  border: 4px solid #ff6439;
  box-shadow: 0px 3px 30px #00000021;
}
@media (max-width: 767.98px) {
  .panel-form {
    // margin: 30px 0;
    padding: 15px;
    box-shadow: unset !important;
    border: 0;
  }
}
@media only screen and (min-width: 767.98px) {
  .text-error {
    font-size: 15px;
  }
}
</style>
